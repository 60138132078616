<!-- Finder particular de Usuarios -->

<template>
  <div class="usuF" v-if="loaded">

    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader">
          </baseHeader>
        </div>

        <!-- Contenido -->
        <div class="contenedor" style="width:800px">

          <!-- Filtro de Busqueda -->
          <div style="display:flex;align-items:center">
            <div v-if="tipoVista" class="columna" style="width:50rem">

              <div style="display:flex">

                <uiText
                  style="width:20%"
                  v-model="ct.name[2]"
                  :label="ct.name[1]"
                  @Enter="eventFiltro('buscar')">
                </uiText>

                <v-btn v-bind="$cfe.btn.busca" @click="eventFiltro('buscar')" class="ma-1">
                  <v-icon >{{ $cfe.btn.busca.icono }}</v-icon>
                </v-btn>

                <!-- <v-select
                  style="width:40%"
                  v-bind="$select"
                  v-model="ct.dep[2]"
                  :label="ct.dep[1]"
                  :items="itemsDeptos"
                  item-value="id"
                  item-text="name"
                  @change="eventFiltro('buscar')">
                </v-select> -->

                <v-checkbox
                  class="ml-2" style="width:20%"
                  v-bind="$checkbox"
                  v-model="ct.bajas[2]"
                  :label="ct.bajas[1]"
                  disabled
                  @change="eventFiltro('buscar')">
                </v-checkbox>

            </div>

            <!-- <div class="columna mt-2">
              <uiText
                v-model="ct.name[2]"
                :label="ct.name[1]"
                @Enter="eventFiltro('buscar')">
              </uiText>
            </div> -->

            </div>
          </div>

          <!-- Grid -->
          <baseGridMD
            style="margin-top:10px"
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="25"
            dense
            @onEvent="execAccion">

              <!-- Slot botonera Top -->
              <template v-slot:gridTop="{}">
                <div>

                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btGridCfg"
                    @onEvent="execAccion">
                  </baseBtraMto>

                </div>
              </template>

              <!-- Slot campo acciones del Grid -->
              <template v-slot:acciones="{ item }">
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>
              </template>

              <!-- Slot activo -->
              <template v-slot:activo="{ item }">
                <!-- <v-icon x-small> {{ item.activo=='1'?"mdi-checkbox-marked-circle-outline":"mdi-close-circle" }}</v-icon> -->
                <v-icon color="red" x-small> {{ item.activo=='1'?"":"mdi-close-circle" }}</v-icon>
              </template>

          </baseGridMD>
        </div>
      </v-sheet>


      <!-- Mto Usuarios -->
      <component
        :is="componenteDinamico"
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate
        :storeRaiz="storeName"
        :masterStore="storeName"
        :ctrlCt="ctrlCt"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        @onEvent="$emit('onEvent', $event)">
      </component>
    </div>
  </div>
</template>


<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";
  import ctasM from "@/components/ctasM";

  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto, ctasM },
    props: {},

    data() {
      return {
        stIni: {
          api: "ctasF",
          name:"ctasF",
          titulo:"·CUENTAS",
          mView:'ctasM',
          pView:[]
        },

        itemsDeptos:[]
      };
    },


    methods: {
      iniDataParticular() {

        this.headers = [{
          name:[],
          fields:[
            { text: "Acciones", value: "acciones", sortable: false, width: "10%", slot:true },
            { text: "Cuenta", value: "cta", width: "15%" },
            { text: "Titulo", value: "name", width: "40%" },
            { text: "RS", value: "rs", width: "30%" },
            { text: "NIF", value: "nif", width: "15%" },
            //{ text: "ID", value: "id", width: "15%" },
            //{ text: "Baja", value: "activo", width: "10%", slot:true },
          ]}
        ];

        this.headerGrid= this.headerSet();

        // zonas jefes de zona
        this.itemsDeptos= this.$store.state.datos_iniciales.deptos;
        this.itemsDeptos.unshift({ id:"0", name:"( Selecciona )"});
      },

    },


    computed: {
      tipoVista() {
        if (this.tipoView!= 'FA' && this.tipoView!= 'FL') return true;
        return false;
      }
    },

  };
</script>
